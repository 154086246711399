import React from "react"
import Layout from "../components/Layout/layout"
import ServiceTeaser from "../components/Teaser/service"
import TeamMembersTeaser from "../components/Teaser/wir"
import IntroTeaser from "../components/Teaser/intro"
import Maps from "../components/About/maps"
import TestimonialsTeaser from "../components/Teaser/testimonials"



const IndexPage = () => (
  <Layout
  heroURL= 'https://images.unsplash.com/photo-1473186578172-c141e6798cf4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1900&h=900&q=80&crop=entropy'
  title='travel connection Touristik'
  subTitle='Einfach schönes Reisen.'
  slug = 'Schönes Reisen & persönliche Beratung'
  >
  <IntroTeaser/>
  <TeamMembersTeaser/>
  <TestimonialsTeaser/>
  <ServiceTeaser/>
  <Maps/>
  </Layout>
)

export default IndexPage
