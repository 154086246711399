import React from "react"
import { StaticQuery, graphql } from "gatsby"

const IntroTeaser = () => (
  <StaticQuery
    query={graphql`
      query {
        service: contentfulTcStatic(contentful_id: {eq: "7qjnvtSa1jT5rNpjAnYIgU"}) {
          id
          textContent {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `
    }
    render = {data => (

      <section class="hero is-small is-primary">
      <div class="hero-body">
        <div class="section">

          <h2 class="subtitle is-bold has-text-weight-bold has-text-white">
            <div dangerouslySetInnerHTML={{ __html: data.service.textContent.childMarkdownRemark.html}}/>
          </h2>

        </div>
      </div>
    </section>


    )}
    />
    )



export default IntroTeaser
